<template>
  <div class="spec-list">
    <el-table
      :data="tableData"
      border
      stripe
      v-loading="loading"
      element-loading-text="Data Is Loading..."
      height="70vh"
      style="margin-top: 10px"
    >
      <el-table-column
        type="index"
        :label="labelText('number')"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column prop="itemCode" :label="labelText('item_code')" width="220">
      </el-table-column>
      <el-table-column prop="productName" :label="labelText('product_name')" width="360">
      </el-table-column>
      <!-- <el-table-column prop="productType" :label="labelText('product_type')" width="120">
      </el-table-column>
      <el-table-column
        prop="productDescription"
        :label="labelText('product_description')"
        width="400"
        align="center"
      >
      </el-table-column> -->
      <el-table-column prop="verified" :label="labelText('verify')" align="center" width="70">
        <template slot-scope="scope">
          <i
            :class="verify_show(scope.row).icon"
            :style="{ color: verify_show(scope.row).color }"
          ></i>
        </template>
      </el-table-column>
      <el-table-column prop="revNumber" :label="labelText('rev')" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="create_name"
        :label="labelText('create_by')"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        :label="labelText('create_time')"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ getFormatterTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="updateName"
        :label="labelText('update_by')"
        width="140"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="updateTime"
        :label="labelText('update_time')"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ getFormatterTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="verifiedName"
        :label="labelText('verified_by')"
        width="100"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="verifiedTime"
        :label="labelText('verified_time')"
        width="160"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ getFormatterTime(scope.row.verifiedTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="this.$t('action.action')"
        fixed="right"
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="editRowHandle(scope.row)"
            >{{ $t('action.edit') }}</el-button
          >
          <el-button
            type="success"
            plain
            size="mini"
            @click="copyRowHandle(scope.row)"
            >{{ $t('action.copy') }}</el-button
          >
          <el-button
            type="danger"
            plain
            size="mini"
            @click="deleteRowHandle(scope.row.id)"
            v-if="isAdminAuth"
            >{{ $t('action.delete') }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="table-pagition">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageIndex"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import { apiServer } from "@/network/modules/detail-api";
export default {
  computed: {
    isAdminAuth() {
      return !!(
        this.username === "JamesChen" ||
        this.username === "admin" ||
        this.username === "JiaZhang"
      );
    },
    labelText() {
      return (label) => {
        let key = "list." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
  },
  data() {
    return {
      loading: false, //数据加载中
      username: "JamesChen",
      tableData: [], //表格数据
      total: 0, //总数
      params: {
        //页面参数
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
        verifiedList: 0,
      },
    };
  },
  created() {
  },
  mounted() {
    // 加载数据
    this.params.itemCode = "";
    this.params.productName = "";
    this.params.pageIndex = 1;
    this.getListData();
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      // 行变色
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },
    searchListData(productName,itemCode,verifiedList){
      this.params.pageIndex = 1;
      this.params.productName = productName;
      this.params.itemCode = itemCode;
      this.params.verifiedList = verifiedList
      this.getListData();
    },
    getListData() {
      this.loading = true;
      if(typeof(this.$route.query.verifyList)!='undefined'){
        this.params.verifiedList = 1;
      }

      this.params.pageSize = 20;
      apiServer.getDataByType("spec", "getList", this.params, (data) => {
        this.params.pageIndex = data.pageIndex;
        this.params.pageSize = data.pageSize;
        this.total = data.total;
        this.tableData = data.records;
        this.loading = false;
      });
    },
    // 加载 itemCode 数据
    getItemCodeListData(params,callback) {
      apiServer.getDataByType("spec", "itemCodeList", params, (data) => {
        callback(data);
      });
    },
    // verify 列图标显示颜色
    verify_show(val) {
      switch (val.verified) {
        case 1:
          return { icon: "el-icon-success", color: "#67c23a" };
        case 0:
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    // 格式化时间
    getFormatterTime(time) {
      if (time) {
        return base.methods.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    // 分页方法
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getListData();
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getListData();
    },
    editRowHandle(row) {
      let language = this.$route.query.language??"EN"
      localStorage.setItem('language',language)
      this.$router.push({
        path: "detail",
        query: { id: row.id,type:'spec', isCopy: false,language },
      });
    },
    // 复制行
    copyRowHandle(row) {
      this.$confirm(this.$t('confirm.copy'), this.$t('confirm.tips'), {
        confirmButtonText: this.$t('confirm.ok'),
        cancelButtonText: this.$t('confirm.cancel'),
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        let language = this.$route.query.language??"EN"
        localStorage.setItem('language',language)
        this.$router.push({
          path: "detail",
          query: { id: row.id, type:'spec', isCopy: true,language },
        });
      });
    },
    deleteRowHandle(id) {
      this.$confirm(this.$t('confirm.delete'), this.$t('confirm.tips'), {
        confirmButtonText: this.$t('confirm.ok'),
        cancelButtonText: this.$t('confirm.cancel'),
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        apiServer.getDataByType("spec", "delete", {id}, () => {
        this.getListData(this.params);
        this.$message({ type: "success", message: "Delete SuccessFully" });
      });
      });
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/spec-list.css";
</style>