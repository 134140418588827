import * as defaultData from "../../spec/data/DefaultData";

export const EN_Coa = {
  base_info: {
    product_type: {
      type: "select",
      label: "Product Type",
      tips_value: "",
      options_value: defaultData.ProductTypeData,
      value: "CSFMU-B",
      disabled: false,
      display: true,
      eventHandle: "productTypeChange",
    },
    item_code: {
      type: "input",
      label: "Item Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_code: {
      type: "input",
      label: "Customer Code",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    product_name: {
      type: "input",
      label: "Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    customer_product_name: {
      type: "input",
      label: "Customer Product Name",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    lot: {
      type: "input",
      label: "Lot",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    version_number: {
      type: "textarea",
      label: "Version Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    print_title: {
      type: "input",
      label: "Print Title",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    po_number: {
      type: "input",
      label: "Po Number",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    expiration_date: {
      type: "date",
      label: "Expiration Date",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    manufacuture_date: {
      type: "date",
      label: "Manufacuture Date",
      tips_value: false,
      value: "",
      disabled: false,
      display: true,
      checked: false,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom Field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "custom_field",
    },
  },
  general_specification: {
    ingredients: {
      type: "tag",
      label: "Ingredients",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    common_other_name: {
      type: "tag",
      label: "Common Other Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    inci_name: {
      type: "tag",
      label: "Inci Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    botanical_name: {
      type: "tag",
      label: "Botanical Name",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    part_used: {
      type: "tag",
      label: "Part Used",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    percentage: {
      type: "tag",
      label: "Percentage",
      tips_value: [],
      value: "",
      disabled: false,
      display: true,
    },
    country_of_origin: {
      type: "input",
      label: "Country Of Origin",
      tips_value: "China",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_ratio: {
      type: "input",
      label: "Extraction Ratio",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    important_constitue_in_plant: {
      type: "input",
      label: "Important Constitue In Plant",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    major_constituents_in_native_herbs: {
      type: "input",
      label: "Major Constituents In Native Herbs",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    appearance: {
      type: "input",
      label: "Appearance",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    solubility: {
      type: "input",
      label: "Solubility",
      tips_value: "Water soluble/dispersible",
      value: "",
      disabled: false,
      display: true,
    },
    homogeneity: {
      type: "input",
      label: "Homogeneity",
      tips_value: "Completely homogenous",
      value: "",
      disabled: false,
      display: true,
    },
    extraction_process: {
      type: "input",
      label: "Extraction Process",
      tips_value:
        "Controlled-temperature water extraction process, spray dried.",
      value: "",
      disabled: false,
      display: true,
    },
    processing_aid: {
      type: "input",
      label: "Processing Aid",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    carrier: {
      type: "input",
      label: "Carrier",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    solvent_for_extraction: {
      type: "input",
      label: "Solvent For Extraction",
      tips_value: "",
      value: "",
      disabled: false,
      display: true,
    },
    certification: {
      type: "input",
      label: "Certification",
      tips_value: "Kosher certified, HALAL certified",
      value: "",
      disabled: false,
      display: true,
    },
    product_grade: {
      type: "input",
      label: "Product Grade",
      tips_value: "Conforms to USP<565>",
      value: "",
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "input",
      label: "Pesticides",
      tips_value: "Conforms to USP<565>",
      value: "",
      disabled: false,
      display: true,
    },
    preservative: {
      type: "input",
      label: "Preservative",
      tips_value: "Conforms to USP<565>",
      value: "",
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "custom_input",
      label: "Custom Field",
      tips_value: "",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "gs_custom_field",
    },
  },
  analysis: {
    ph: {
      type: "three_input",
      label: "Ph",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    total_heavy_metals: {
      type: "three_input",
      label: "Total Heavy Metals",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    arsenic: {
      type: "three_input",
      label: "Arsenic",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    cadmium: {
      type: "three_input",
      label: "Cadmium",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    lead: {
      type: "three_input",
      label: "Lead",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    mercury: {
      type: "three_input",
      label: "Mercury",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    pesticides: {
      type: "three_input",
      label: "Pesticides",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    color: {
      type: "three_input",
      label: "Color",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    odor: {
      type: "three_input",
      label: "Odor",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    taste: {
      type: "three_input",
      label: "Taste",
      tips_value:"",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "three_custom_input",
      label: "Custom Field",
      tips_value:"",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "an_custom_field",
    },
  },
  microbiological_tests: {
    total_plate_count: {
      type: "three_input",
      label: "Total Plate Count",
      tips_value: "",
      value:{ specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    yeast_and_mold: {
      type: "three_input",
      label: "Yeast And Mold",
      tips_value: "",
      value: { specification: "", result:"", test_method: "" },
      disabled: false,
      display: true,
    },
    custom_field: {
      type: "three_custom_input",
      label: "Custom Field",
      tips_value:"",
      value: [
        {
          fields: "",
          value: "",
          specification: "",
          test_method: "",
        },
      ],
      disabled: false,
      display: true,
      fields: "",
      attr: "mt_custom_field",
    },
  }
};
