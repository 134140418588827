<template>
  <div>
    <el-form :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in zdFormattedValue" :key="index">
        <el-form-item :label="labelText('Rev')">
          {{ index + 1 }}
        </el-form-item>
        <el-form-item :label="labelText('Revision Date')">
          <el-date-picker
            v-model="item.revision_date"
            :placeholder="labelText('RevisionDate')" 
            :disabled="item.isComplete"
            format="MM/dd/yyyy"
            type="date"
            style="width: 140px">
        </el-date-picker>
        </el-form-item>
        <el-form-item :label="labelText('Requested By')">
          <el-input
            v-model="item.requestedBy"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Requested By')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Revised Notes')">
          <el-input
            v-model="item.itemsRevised"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Revised Notes')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Edited By')">
          <el-input
            v-model="item.editedBy"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Edited By')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Reason')">
          <el-input
            v-model="item.reason"
            :disabled="optionButtonFlag(item)"
            :placeholder="labelText('Reason')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="labelText('Approved By')">
          <el-input
            v-model="item.approvedBy"
            :label="labelText('Approved By')"
            :disabled="item.isComplete"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            :disabled="index < zdFormattedValue.length - 1"
            v-if="showAddRecords(item)"
            @click="completeHandle(item)"
            >{{ $t("action.complete") }}</el-button
          >
          <el-button
            type="text"
            :disabled="index < zdFormattedValue.length - 1"
            v-if="showAddRecords(item)"
            @click="deleteHandle(index)"
            >{{ $t("action.delete") }}</el-button
          >
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDRevisionComponents",
  props: {
    zdId: {
      type: String,
      required: true,
    },
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    console.info('this.zdItem<><><>:',this.zdItem)
    this.initRevision();
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    completeHandle() {
      let revision = {
        approvedBy: "",
        editedBy: "",
        isComplete: false,
        reason: "",
        requestedBy: "",
        revisedNotes: "",
        revisionDate: "",
      };
      this.zdItem.value.push(revision);
    },
    initRevision() {
      let lastItem =
        this.zdFormattedValue[this.zdFormattedValue.length - 1] || {};
      if(this.zdFormattedValue.length==0){
        this.zdFormattedValue = []
      }
      if (lastItem.isComplete||this.zdFormattedValue.length==0) {
        let revision = {
          approvedBy: "",
          editedBy: "",
          isComplete: false,
          reason: "",
          requestedBy: "",
          revisedNotes: "",
          revisionDate: "",
        };
        this.zdItem.value.push(revision); 
      }
    },
    optionButtonFlag(item) {
      let pageStatus = localStorage.getItem("pageStatus");
      if (pageStatus == "Detail") {
        return true;
      }
      return item.isComplete;
    },
    showAddRecords(item) {
      if (item.isComplete) {
        return item.isComplete;
      }
      return true;
    },
    deleteHandle(index) {
      this.zdFormattedValue.splice(index, 1);
    },
  },
};
</script>
