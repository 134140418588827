<template>
  <div class="new-sds-container" v-loading="loading">
    <el-tabs type="border-card" v-model="tabModel" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"><i class="el-icon-date"></i> Detail Manager</span>
        <ZDComponentsVue
          v-if="isComponentsLoad"
          :modules="detailData"
          :tabId="'tab1'"
          :key="detailDataKey"
        ></ZDComponentsVue>
      </el-tab-pane>
      <el-tab-pane
        :lazy="true"
        v-for="(item, index) in auditedList"
        :key="item.id + index"
        :name="'audited_tab' + (index + 2)"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> Version {{ index + 1 }}
        </span>
        <ZDComponentsVue
          :modules="getAuditedData(item)"
          :tabId="'tab' + (index + 2)"
        ></ZDComponentsVue>
      </el-tab-pane>
    </el-tabs>
    <ZDRevPopover
      v-if="pageStatus != 'Add'"
      :pageStatus="pageStatus"
      :versionInfo="versionInfo"
    ></ZDRevPopover>
  </div>
</template>

<script>
import ZDComponentsVue from "./ZDComponents.vue";
import ZDRevPopover from "./zd-modules/ZDRevPopover.vue";
import { apiServer } from "@/network/modules/detail-api";
import * as TemplateData from "@/views/NEW/module/template/index.js";
export default {
  components: {
    ZDComponentsVue,
    ZDRevPopover,
  },
  computed: {
    isComponentsLoad() {
      return this.detailData && Object.keys(this.detailData).length;
    },
  },
  data() {
    return {
      loading: false, // 加载中
      auditedList: [], // 版本信息
      tabModel: "tab1",
      pageStatus: "Add", // 页面状态：Add、Detail、Edit
      productType: "CSFMU-B", //默认的产品类型，spec 为 CSFMU-B、COA 为 CSFMU-A
      itemCode: "",
      detailData: {}, //详情数据
      defaultLoadData: {},
      notesData: {}, //备注信息
      detailDataKey: 0,
      versionInfo: {
        revNumber: "",
        createName: "",
        createTime: "",
        updateName: "",
        updateTime: "",
        verifiedName: "",
        verifiedTime: "",
      },
    };
  },
  provide() {
    return {
      productTypeChange: this.productTypeChange,
      getNotesDataMethod: this.getNotesData,
      setNotesDataMethod: this.setCoaNotes,
      importSpecDataMethod: this.loadSpecDataByItemCode,
      setItemCodeDataMethod: this.setItemCode,
      setupProductDescription: this.setupProductDescription
    };
  },
  created() {
    let id = this.$route.query.id;
    let isCopy = this.$route.query.isCopy;
    let language = this.$route.query.language;
    if(language=='zh'){
      localStorage.setItem("language", "cn");
    }


    let status = "Add";
    if (isCopy === true) {
      this.loadData();
    } else if (id) {
      status = "Edit";
      this.loadData();
      this.loadAuditVersionData();
    } else {
      this.loadTemplateData();
    }

    this.pageStatus = status;

    localStorage.setItem("pageStatus", this.pageStatus);
    localStorage.setItem("currentTabName", this.tabModel);
  },
  methods: {
    setItemCode(itemCode,callback) {
      this.itemCode = itemCode;
      let type = this.$route.query.type;
      if (this.pageStatus == "Add" && type == "spec" && itemCode) {

        // 判断 itemCode 是否已经存在
        apiServer.getDataByType(
        "spec",
        "itemCodeExist",
        { itemCode },
        (data) => {
          // console.info("data:", data);

          if (data) {
            this.$message({
              showClose: true,
              message: this.$t("confirm.item_code_already_exists"),
              type: 'error'
            });
          }
          if(callback){
            callback();
          }
        });
      }
    },
    getNotesData() {
      return this.notesData;
    },
    setCoaNotes(notes) {
      this.notesData = Object.assign({}, notes);
    },
    // tab切换
    handleTabClick(tab) {
      // console.info(tab.name);
      // console.info("tdetailDataKey", this.detailDataKey);
      if (tab.name === "tab1") {
        this.pageStatus = "Edit";
      } else {
        this.pageStatus = "Detail";
      }
      localStorage.setItem("pageStatus", this.pageStatus);
      localStorage.setItem("currentTabName", this.tabModel);
      this.$EventBus.$emit("pageStatusChange", this.pageStatus);
    },
    // 产品类型切换
    productTypeChange(type) {
      this.loadTemplateData(type);
      this.setupProductDescription("");
    },
    // 加载详情数据
    loadData() {
      // console.info("loadData==");
      this.loading = true;
      let type = this.$route.query.type;
      let id = this.$route.query.id;

      apiServer.getDataByType(type, "getDetail", { id }, (data) => {
        this.setupVersionInfo(data);
        let tmpData = JSON.parse(data.jsonValue);

        // 保存默认数据
        this.defaultLoadData = tmpData;

        if (tmpData.notes) {
          this.notesData = tmpData.notes;
        }
        // console.info("json>>:", tmpData);

        let productType = this.getCoaType(data.productType);
        this.productType = productType;

        const template = TemplateData.config.getDataByType(productType);
         
        let templateVal = TemplateData.config.valueToTemplate(
          template,
          tmpData
        );

        console.info("templateVal>>>:", templateVal);

        // revision 数据
        const isCopy = !this.$route.query.isCopy;
        if (tmpData.revision && isCopy==false) {
          templateVal.rev.revision.value = tmpData.revision;
        }
      
        // coa 权限设置
        this.coaAuthSetup(templateVal);

        // console.info("templateVal>>>:", templateVal);
        this.detailData = templateVal;
        localStorage.setItem("verifiedTime", data.verifiedTime);

        // console.info("tmp---:", templateVal);
        // this.$set(this, "detailData", templateVal || {});
        this.loading = false;
      });
    },
    coaAuthSetup(templateVal) {
      let type = this.$route.query.type;
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      // 如果产品类型为 coa,并且用户角色为上海用户，则 base_info 的信息都设置为禁用
      if (userInfo.roleId == 3 && type == "coa") {
        Object.keys(templateVal.base_info).forEach((key) => {
          if ("disabled" in templateVal.base_info[key]) {
            templateVal.base_info[key].disabled = true;
          }
        });
        Object.keys(templateVal.general_specification).forEach((key) => {
          if ("disabled" in templateVal.general_specification[key]) {
            templateVal.general_specification[key].disabled = true;
          }
        });
      }
    },
    // 加载版本数据
    loadAuditVersionData() {
      this.loading = true;
      let type = this.$route.query.type;
      if (type == "coa") {
        // coa 没有版本数据
        // console.info("return coa");
        return;
      }
      let id = this.$route.query.id;
      apiServer.getDataByType(type, "auditedList", { id }, (data) => {
        this.$set(this, "auditedList", data || []);
        this.loading = false;
      });
    },
    loadSpecDataByItemCode(callback) {
      // this.detailData = this.defaultLoadData;
      let itemCode = this.detailData.base_info.item_code.value;
      apiServer.getDataByType(
        "spec",
        "auditedLatestInfo",
        { itemCode },
        (data) => {
          // console.info("data$$:", data);
          let tmpData = JSON.parse(data.jsonValue);
          if (tmpData.notes) {
            this.notesData = tmpData.notes;
          }

          let productType = this.getCoaType(data.productType);
          const template = TemplateData.config.getDataByType(productType);
          let templateVal = TemplateData.config.valueToTemplate(
            template,
            tmpData
          );

          // console.info("spec---templateVal:", templateVal);
          this.updateValueBySpecData(templateVal);
          if (callback) {
            callback();
          }
        }
      );
    },
    setupVersionInfo(data) {
      const {
        createName = "",
        createTime = "",
        updateName = "",
        updateTime = "",
      } = data;
      if (createName != null) {
        this.versionInfo.createName = createName;
      }
      if (createTime != null) {
        this.versionInfo.createTime = createTime?.substring(0, 10);
      }
      if (updateName) {
        this.versionInfo.updateName = updateName;
      }
      if (updateTime != null) {
        this.versionInfo.updateTime = updateTime?.substring(0, 10);
      }
    },
    updateValueBySpecData(templateVal) {
      // console.info("start defaultLoadData:", this.defaultLoadData.base_info);
      // base_info “print_title”,  “product_name”, “customer_product_name”,  “product_type“, “version_number”

      const baseInfoKeys = [
        "print_title",
        "product_name",
        "customer_product_name",
        "version_number",
      ];
      baseInfoKeys.forEach((key) => {
        if (templateVal.base_info[key].value !== undefined) {
          this.detailData.base_info[key].value =
            templateVal.base_info[key].value;
        }
      });

      // general_specification
      if (templateVal.general_specification) {
        for (const key in templateVal.general_specification) {
          if (
            this.detailData.general_specification[key] &&
            templateVal.general_specification[key] !== undefined
          ) {
            if (
              templateVal.general_specification[key].value &&
              templateVal.general_specification[key].value.length > 0
            ) {
              this.detailData.general_specification[key].value =
                templateVal.general_specification[key].value;
            }
          }
        }
      }

      // 更新 analysis、microbiological_tests、fatty_acid 的值
      const modulesToUpdate = [
        "analysis",
        "microbiological_tests",
        "fatty_acid",
      ];
      modulesToUpdate.forEach((module) => {
        if (templateVal[module] && this.detailData[module]) {
          const moduleValue = templateVal[module].value || {}; // 确保 moduleValue 不为 undefined
          if (moduleValue.specification !== undefined) {
            this.detailData[module].value.specification =
              moduleValue.specification;
          }
          if (moduleValue.test_method !== undefined) {
            this.detailData[module].value.test_method = moduleValue.test_method;
          }
        }
      });

      // other_notes
      // this.detailData = templateVal;
    },
    // 绑定 module
    getAuditedData(item) {
      let tmpData = JSON.parse(item.jsonValue);

      const mergedData = TemplateData.config.mergeTemplateWithSavedData(
        item.productType,
        tmpData,
        true
      );
      // console.info("getAuditedData:", mergedData);
      return mergedData;
    },
    getCoaType(productType) {
      let type = this.$route.query.type;
      if (type == "coa") {
        let lang = localStorage.getItem("language").toUpperCase();
        if (lang == "ZH") {
          lang = "CN";
        }
        productType = lang + "-Coa";
      }
      // console.info("productType>>:", productType);
      return productType;
    },
    // 加载模板数据
    loadTemplateData(productType = "CSFMU-B") {
      this.productType = productType;
      let language = localStorage.getItem("language");
      let type = this.$route.query.type;
      if (type == "coa") {
        // console.info("lang====>",language.toLocaleLowerCase());
        if (language.toLocaleLowerCase() == "cn" || language == "zh") {
          this.productType = "CN-Coa";
        } else {
          this.productType = "EN-Coa";
        }
      }
      console.info("productType==:", productType);

      // 切换模板
      let templateData = TemplateData.config.getDataByType(this.productType);
      // console.info("templateData:", templateData);
      let valueDetail = templateData;
      if (this.defaultLoadData.base_info) {
        this.defaultLoadData.base_info.product_type = productType;
      }
      if (
        this.defaultLoadData &&
        Object.keys(this.defaultLoadData).length > 0
      ) {
        // 加载内容到模板中
        valueDetail = TemplateData.config.valueToTemplate(
          templateData,
          this.defaultLoadData
        );
      } else {
        // 设置默认值
        valueDetail = TemplateData.config.assignTipsValue(valueDetail);
        
      }

      console.info("valueDetail》》》》》:", valueDetail);
      // 使用 Vue.set 以确保响应式
      this.$set(this, "detailData", valueDetail || {});
      this.detailDataKey += 1; // 强制重新渲染 ZDComponentsVue
    },
    setupProductDescription(value){
      //  TY-EHXTO、TY-SHXTO 类别
      let language = localStorage.getItem("language");
      if(language.toLocaleLowerCase() == "cn" || language == "zh"){
        if(this.productType == "TY-EHXTO" || this.productType == "TY-SHXTO"){
          let description = this.detailData.base_info.product_description.tips_value.replace("${general_specification.common_other_name}",value)
          this.detailData.base_info.product_description.value = description
        }else if(this.productType == "TY-P" || this.productType == "TY-L"){
          let description = this.detailData.base_info.product_description.tips_value.replace("${general_specification.botanical_name}",value)
          this.detailData.base_info.product_description.value = description
        }
      }
     
    }
  },
}
</script>

<style scoped>
</style>
