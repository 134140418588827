<template>
  <div>
    <el-tabs v-model="activeTab">
      <!-- 第一个标签页：增加测试数据 -->
      <el-tab-pane label="增加测试数据" name="testData">
        <div class="countdown-wrapper" v-if="countdown > 0">
          <span class="countdown-text">下次可添加时间：</span>
          <span class="countdown-number">{{ countdown }}</span>
          <span class="countdown-unit">秒</span>
        </div>
        
        <el-button type="primary" @click="dialogVisible = true">添加</el-button>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="ID"></el-table-column>
          <el-table-column prop="name" label="姓名"></el-table-column>
          <el-table-column prop="age" label="年龄" ></el-table-column>
          <el-table-column prop="sex" label="性别" >
            <template slot-scope="scope">
              {{ scope.row.sex === '1' ? '男' : '女' }}
            </template>
          </el-table-column>
          <el-table-column prop="address" label="地址"></el-table-column>
        </el-table>

        <el-dialog title="添加新用户" :visible.sync="dialogVisible" width="30%">
          <el-form :model="newUser" label-width="80px">
            <el-form-item label="姓名">
              <el-input v-model="newUser.name"></el-input>
            </el-form-item>
            <el-form-item label="年龄">
              <el-input v-model="newUser.age"></el-input>
            </el-form-item>
            <el-form-item label="性别">
              <el-select v-model="newUser.sex" placeholder="请选择性别">
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="newUser.address"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addUser">确 定</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>

      <!-- 第二个标签页：触发器列表 -->
      <el-tab-pane label="触发器列表" name="triggerList">

        <el-table :data="triggerList" style="width: 100%">
          <el-table-column prop="id" label="ID" ></el-table-column>
          <el-table-column prop="name" label="触发器名称" ></el-table-column>
          <el-table-column prop="target" label="发送目标">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.target" placement="top">
                <span>{{ scope.row.target | truncate }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="trigger_content" label="条件 SQL">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.condition_sql" placement="top">
                <span>{{ scope.row.condition_sql | truncate }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="template_id" label="模板"></el-table-column>
          <el-table-column prop="trigger_content" label="发送内容">
            <template slot-scope="scope">
              <el-tooltip :content="scope.row.trigger_content" placement="top">
                <span>{{ scope.row.trigger_content | truncate }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="version" label="版本" ></el-table-column>
          <el-table-column prop="is_active" label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.is_active ? 'success' : 'info'">
                {{ scope.row.is_active ? '启用' : '停用' }}
              </el-tag>
            </template>
          </el-table-column>
          
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      newUser: {
        id: null,
        name: '',
        age: null,
        sex: '',
        address: ''
      },
      countdown: 0,
      timer: null,
      
      activeTab: 'testData',
      triggerList: [],
      triggerDialogVisible: false,
      currentTrigger: {
        name: '',
        condition_sql: '',
        target: '',
        trigger_content: '',
        version_content: '',
        is_active: 1,
        template_id: 1
      }
    };
  },
  filters: {
    truncate(value) {
      if (!value) return '';
      if (value.length <= 20) return value;
      return value.slice(0, 20) + '...';
    }
  },
  created() {
    this.loadData();
    this.loadTriggers();
  },
  methods: {
    loadData() {
      this.$newApi.emailTriggers.testEmaiList().then(res => {
        console.log(res);
        this.tableData = res;
      });
    },
    startCountdown() {
      this.countdown = 60;
      clearInterval(this.timer);
      
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    addUser() {
      this.$newApi.emailTriggers.testEmailAdd(this.newUser).then(res => {
        console.log(res);
        this.loadData();
        this.dialogVisible = false;
        this.startCountdown();
      });
    },
    loadTriggers() {
      this.$newApi.emailTriggers.emailTriggerList().then(res => {
        this.triggerList = res;
      });
    },
    addTrigger() {
      // 实现新增触发器的逻辑
    },
    editTrigger() {
      // 实现编辑触发器的逻辑
    },
    toggleTriggerStatus(row) {
      const newStatus = !row.is_active;
      this.$newApi.emailTriggers.updateTriggerStatus({
        id: row.id,
        is_active: newStatus
      }).then(() => {
        this.loadTriggers();
        this.$message.success(`触发器已${newStatus ? '启用' : '停用'}`);
      });
    },
    deleteTrigger(row) {
      this.$confirm('确认删除该触发器吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$newApi.emailTriggers.deleteTrigger(row.id).then(() => {
          this.loadTriggers();
          this.$message.success('删除成功');
        });
      }).catch(() => {});
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
};
</script>

<style scoped>
.countdown-wrapper {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #f5f7fa;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}

.countdown-text {
  color: #606266;
  font-size: 14px;
}

.countdown-number {
  color: #409EFF;
  font-size: 18px;
  font-weight: bold;
  margin: 0 5px;
}

.countdown-unit {
  color: #606266;
  font-size: 14px;
}

.trigger-header {
  margin-bottom: 20px;
}
</style>