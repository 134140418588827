<template>
  <div class="new-sds-container" v-loading="loading">
    <el-tabs type="border-card" v-model="tabModel" @tab-click="handleTabClick">
      <el-tab-pane
        v-loading="loading"
        element-loading-text="data loading"
        name="tab1"
        class="tab"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> {{ tabTitle("Detail Manager") }}</span
        >
        <SdsContentComponentsVue
          v-if="detailData"
          :detailData="detailData"
          :language="language"
          :pageStatus="pageStatus"
          @save-sds="saveHandle"
          @load-spec-item-code="loadSpecItemCodeData"
        ></SdsContentComponentsVue>
      </el-tab-pane>
      <el-tab-pane
        v-for="(item, index) in auditedList"
        :key="index"
        :name="'tab' + (index + 2)"
      >
        <span slot="label"
          ><i class="el-icon-date"></i> {{ tabTitle("Version") }}
          {{ item.revNumber }}
        </span>
        <SdsContentComponentsVue
          :detailData="item"
          :specData="specData"
          :language="language"
          :pageStatus="pageStatus"
        ></SdsContentComponentsVue>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import SdsContentComponentsVue from "./components/SdsContentComponents.vue";
import { apiServer } from "@/network/modules/detail-api";
export default {
  components: {
    SdsContentComponentsVue,
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      form: {},
      tabModel: "tab1",
      pageStatus: "Edit",
      language: "EN",
      revisionData: [],
      auditedList: [],
      detailData: {
        id: "",
        item_code: "",
        product_name: "",
        customer_product_name: "",
        composition_information_on_ingredients:[{
          cas_number:"",
          einecs_number:"",
          r_phrase:"",
          s_phrase:"",
          substance_name:""
        }],
        botanicalName: "",
        inci_name: "",
        appearance: "",
        solubility: "",
        ph: "",
        color: "",
        odor: "",
        storage: ""
      },
      rowData: [
        {
          substanceName: "",
          casNumber: "",
          einecsNumber: "",
          rPhrase: "",
          sPhrase: "",
        },
      ],
      specData: {},
    };
  },
  computed: {
    tabTitle() {
      return function (title) {
        if (this.language == "EN") {
          return title;
        }
        if (title == "Detail Manager") {
          return "详情管理";
        } else if (title == "Version") {
          return "版本";
        }
      };
    },
    sdsTypeList() {
      if (this.$route.query.language === "EN") {
        return [
          { name: "Liquid", value: "Liquid" },
          { name: "Oil", value: "Oil" },
          { name: "Powder", value: "Powder" },
        ];
      }
      return [
        { name: "TY-L", value: "TY-L" },
        { name: "TY-Oil", value: "TY-Oil" },
        { name: "TY-P", value: "TY-P" },
      ];
    },
    selectPlaceholderText() {
      if (this.$route.query.language === "EN") {
        return "Please Select";
      } else {
        return "请选择";
      }
    },
    placeholderText() {
      if (this.$route.query.language === "EN") {
        return "Please Input";
      } else {
        return "请输入";
      }
    },
  },
  methods: {
    handleTabClick(tab) {
      if (tab.name === "tab1") {
        let params = { ...this.$route.query };
        if (params.id) {
          this.pageStatus = "Edit";
        } else {
          this.pageStatus = "Add";
        }
      } else {
        this.pageStatus = "Detail";
      }
    },
    // 加载详情数据
    loadDetailData() {
      console.info("loadData==");

      let type = this.$route.query.type;
      let id = this.$route.query.id;
      if (id) {
        this.loading = true;
        apiServer.getDataByType(type, "getDetail", { id }, (data) => {
          if(typeof(data.sdsType)=='undefined'||data.sdsType==null){
            data.sdsType = 'Powder';
          }
          // console.info("data:",data)
          let jsonValue = this.handleCopyData(data);
          
          // console.info("return jsonValue11:", jsonValue);
          if (this.$route.query.isCopy === "true") {
            jsonValue.itemCode = "";
            jsonValue.revision = [];

            this.detailData = jsonValue;
            this.detailData.id = "";
            
          } else {
            this.detailData.id = data.id;
            this.detailData = jsonValue;
            console.info('load data ==>this.detailData:',this.detailData)
          }
          this.loading = false;
        });
      }else{
        // 初始化新建对象
        console.info('this.detailData..:',this.detailData)
      }
    },
    loadAuditListData() {
      let type = this.$route.query.type;
      let id = this.$route.query.id;
      if (id) {
        this.loading = true;
        apiServer.getDataByType(type, "auditedList", { id }, (data) => {
          data.forEach(item => {
            let jsonValue = this.handleCopyData(item);
            this.auditedList.push(jsonValue)
          })
          console.info('tmp:',this.auditedList)
          
          this.loading = false;
        });
      }
    },
    loadSpecItemCodeData(specModel) {
      console.info('specModel1:',specModel)
      try {
        this.detailData.print_title = specModel.base_info.print_title || '';
        this.detailData.product_name = specModel.base_info.product_name || '';
        this.detailData.customer_product_name = specModel.base_info.customer_product_name || '';

        this.detailData.botanical_name = specModel.general_specification.botanical_name? specModel.general_specification.botanical_name.toString() : '';
        this.detailData.inci_name = specModel.general_specification.inci_name ? specModel.general_specification.inci_name.toString() : '';
        this.detailData.appearance = specModel.general_specification.appearance? specModel.general_specification.appearance.toString() : '';
        this.detailData.solubility = specModel.general_specification.solubility? specModel.general_specification.solubility.toString() : '';

        this.detailData.specific_gravity = specModel.analysis.specific_gravity?.specification ?? '';
        this.detailData.ph = specModel.analysis.ph?.specification ?? '';
        this.detailData.color = specModel.analysis.color?.specification ?? '';
        this.detailData.odor = specModel.analysis.odor?.specification ?? '';

        this.detailData.storage = specModel.other_notes.storage || '';

        
        let paramsId = this.$route.query.id;
        console.info('paramsId',paramsId)
        if(typeof(paramsId)=="undefined" || paramsId==null){
          this.detailData.composition_information_on_ingredients = []
          for(let i=0;i<specModel.general_specification.inci_name.length;i++){
            this.detailData.composition_information_on_ingredients.push({
              substance_name: specModel.general_specification.inci_name[i],
              cas_number: '',
              einecs_number: '',
              r_phrase: '',
              s_phrase: '',
            });
          }
        }
          
      } catch (error) {
        console.error("加载规格项数据时发生错误:", error);
        this.$message.error("加载数据失败，请稍后重试。");
      }
      
    },
    getTabAuditDetailData(item) {
      let tmp = JSON.parse(item.jsonValue);
      console.info('getTabAuditDetailData:',tmp)
      return tmp;
    },
    // 添加行数据
    addRowDataHandle() {
      this.rowData.push({
        substanceName: "",
        casNumber: "",
        einecsNumber: "",
        rPhrase: "",
        sPhrase: "",
      });
    },
    // 删除行数据
    removeRow(idx) {
      this.rowData.splice(idx, 1);
    },
    addVerifyListHandle() {},
    saveHandle(params, callback) {
      let type = this.$route.query.type;

      apiServer.getDataByType(type, "saveOrUpdate", params, (data) => {
        this.loading = false;
        callback(data);
        this.$message({
          type: "success",
          message: "Update Success",
        });
      });
    },
    vertifyHandle() {},
    back() {
      this.$router.go(-1);
    },
    handleCopyData(data) {
      if (typeof data == "undefined") {
        return;
      }
      let jsonValue = JSON.parse(data.jsonValue);
      const convertKeys = (obj) => {
        Object.keys(obj).forEach((key) => {
          const newKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();
          if (newKey !== key) {
            obj[newKey] = obj[key];
            delete obj[key];
          }
          if (typeof obj[newKey] === "object" && obj[newKey] !== null) {
            convertKeys(obj[newKey]);
          }
        });
        return obj;
      };
      jsonValue = convertKeys(jsonValue);
      return jsonValue;
      
    },
  },
  created() {
    this.language = this.$route.query.language;
    this.loadDetailData();
    if (this.$route.query.isCopy != "true") {
      this.loadAuditListData();
    }
  },
};
</script>

<style scoped>
@import "../style/new-spec.css";
</style>
