import * as apiNewUrl from '@/network/new-api-const'
import http from '@/network/http-tools'

const api = {
  enSpecServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newEnSpec.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newEnSpec.infoUrl + '/' + params.id);
    },
    getDetailByItemCode(params){
      return http.fetchGet(apiNewUrl.newEnSpec.infoByItemCodeUrl + '/' + params.itemCode);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newEnSpec.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newEnSpec.updateUrl, params);
    },
    delete(params){
      return http.fetchPost(apiNewUrl.newEnSpec.deleteUrl + '/' + params.id);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newEnSpec.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newEnSpec.verifyUrl, params);
    },
    addVerifyList(params){
      return http.fetchPost(apiNewUrl.newEnSpec.addVerifyListUrl, params);
    },
    itemCodeList(params){
      return http.fetchPost(apiNewUrl.newEnSpec.itemCodeListUrl, params);
    },
    auditedList(params){
      return http.fetchGet(apiNewUrl.newEnSpec.auditedListUrl + '/' + params.id);
    },
    auditedLatestInfo(params){
      return http.fetchGet(apiNewUrl.newEnSpec.auditedLatestInfoUrl+ '/' + params.itemCode);
    },
    itemCodeExist(params){
      console.info("params:", params);
      return http.fetchGet(apiNewUrl.newEnSpec.itemCodeExistUrl+ '/' + params.itemCode);
    },
  },
  cnSpecServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newCnSpec.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newCnSpec.infoUrl + '/' + params.id);
    },
    getDetailByItemCode(params){
      return http.fetchGet(apiNewUrl.newCnSpec.infoByItemCodeUrl + '/' + params.itemCode);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newCnSpec.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newCnSpec.updateUrl, params);
    },
    delete(params){
      return http.fetchPost(apiNewUrl.newCnSpec.deleteUrl + '/' + params.id);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newCnSpec.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newCnSpec.verifyUrl, params);
    },
    addVerifyList(params){
      return http.fetchPost(apiNewUrl.newCnSpec.addVerifyListUrl, params);
    },
    itemCodeList(params){
      return http.fetchPost(apiNewUrl.newCnSpec.itemCodeListUrl, params);
    },
    auditedList(params){
      return http.fetchGet(apiNewUrl.newCnSpec.auditedListUrl + '/' + params.id);
    },
    auditedLatestInfo(params){
      return http.fetchGet(apiNewUrl.newCnSpec.auditedLatestInfoUrl+ '/' + params.itemCode);
    },
    itemCodeExist(params){
      return http.fetchGet(apiNewUrl.newCnSpec.itemCodeExistUrl+ '/' + params.itemCode);
    },
  },

  enSdsServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newEnSds.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newEnSds.infoUrl + '/' + params.id);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newEnSds.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newEnSds.updateUrl, params);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newEnSds.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newEnSds.verifyUrl, params);
    },
    addVerifyList(params){
      return http.fetchPost(apiNewUrl.newEnSds.addVerifyListUrl, params);
    },
    delete(id){
      return http.fetchPost(apiNewUrl.newEnSds.deleteUrl + '/' + id);
    },
    auditedList(params){
      return http.fetchGet(apiNewUrl.newEnSds.auditedListUrl + '/' + params.id);
    },
    auditedLatestInfo(params){
      return http.fetchGet(apiNewUrl.newEnSds.auditedLatestInfoUrl+ '/' + params.itemCode);
    },
    itemCodeList(params){
      return http.fetchPost(apiNewUrl.newEnSds.itemCodeList, params);
    },
  },
  cnSdsServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newCnSds.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newCnSds.infoUrl + '/' + params.id);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newCnSds.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newCnSds.updateUrl, params);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newCnSds.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newCnSds.verifyUrl, params);
    },
    addVerifyList(params){
      return http.fetchPost(apiNewUrl.newCnSds.addVerifyListUrl, params);
    },
    delete(id){
      return http.fetchPost(apiNewUrl.newCnSds.deleteUrl + '/' + id);
    },
    auditedList(params){
      return http.fetchGet(apiNewUrl.newCnSds.auditedListUrl + '/' + params.id);
    },
    auditedLatestInfo(params){
      return http.fetchGet(apiNewUrl.newCnSds.auditedLatestInfoUrl+ '/' + params.itemCode);
    },
    itemCodeList(params){
      return http.fetchPost(apiNewUrl.newCnSds.itemCodeList, params);
    },
  },

  enCoaServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newEnCoa.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newEnCoa.infoUrl + '/' + params.id);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newEnCoa.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newEnCoa.updateUrl, params);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newEnCoa.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newEnCoa.verifyUrl, params);
    },
    delete(id){
      return http.fetchPost(apiNewUrl.newEnCoa.deleteUrl + '/' + id);
    },
    suggestionsList(params){
      return http.fetchPost(apiNewUrl.newEnCoa.suggestionsList, params);
    },
  },
  cnCoaServer:{
    getList(params){
      return http.fetchPost(apiNewUrl.newCnCoa.pageListUrl, params);
    },
    getDetail(params){
      return http.fetchGet(apiNewUrl.newCnCoa.infoUrl + '/' + params.id);
    },
    add(params){
      return http.fetchPost(apiNewUrl.newCnCoa.addUrl, params);
    },
    update(params){
      return http.fetchPost(apiNewUrl.newCnCoa.updateUrl, params);
    },
    saveOrUpdate(params){
      return http.fetchPost(apiNewUrl.newCnCoa.saveOrUpdateUrl, params);
    },
    verify(params){
      return http.fetchPost(apiNewUrl.newCnCoa.verifyUrl, params);
    },
    delete(id){
      return http.fetchPost(apiNewUrl.newCnCoa.deleteUrl + '/' + id);
    },
    suggestionsList(params){
      return http.fetchPost(apiNewUrl.newCnCoa.suggestionsList, params);
    },
  },
  userManager:{
    userList(params){
      return http.fetchPost(apiNewUrl.userManager.userList, params);
    },
    userAdd(params){
      return http.fetchPost(apiNewUrl.userManager.userAdd, params);
    },
    userUpdate(params){
      return http.fetchPost(apiNewUrl.userManager.userUpdate, params);
    },
    userRemove(params){
      return http.fetchPost(apiNewUrl.userManager.userRemove, params);
    },
    resetUserPassword(params){
      return http.fetchPost(apiNewUrl.userManager.resetUserPassword, params);
    },
  },
  emailTriggers:{
    testEmailAdd(params){
      return http.fetchPost(apiNewUrl.emailTest.testAddEmailUrl, params);
    },
    testEmaiList(params){
      return http.fetchGet(apiNewUrl.emailTest.testEmailUrl, params);
    },
    emailTriggerList(params){
      return http.fetchGet(apiNewUrl.emailTest.emailTriggerList, params);
    }
  },

}

export default api;