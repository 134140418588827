<template>
  <div>
    <el-button
      type="primary"
      @click="recoverHandle"
      :disabled="optionButtonFlag != true"
      >{{ labelText("Recover From This Version") }}</el-button
    >
    <el-button
      type="warning"
      @click="addVerifyListHandle"
      :disabled="optionButtonFlag"
      :loading="addVerifyListLoading"
      >{{ labelText("Add Verify List") }}</el-button
    >
    <el-button
      type="success"
      @click="saveHandle"
      :loading="saveLoading"
      :disabled="optionButtonFlag"
      >{{ labelText("Save") }}</el-button
    >
    <el-button
      type="danger"
      @click="verifyHandle"
      :loading="verifyLoading"
      :disabled="optionButtonFlag"
      >{{ labelText("Verify") }}</el-button
    >
    <el-button
      type="primary"
      @click="previewHandle"
      :loading="previewPdfLoading"
      >{{ labelText("Preview PDF") }}</el-button
    >
    <el-button @click="backHomeClick">{{ labelText("Back Home") }}</el-button>
  </div>
</template>
<script>
// import * as StructConvertUtils from "../../spec/config/structConvertUtils";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import { apiServer } from "@/network/modules/detail-api";
// import * as TemplateUtils from '../../template/index'

export default {
  props: {
    isPrintSignature: Boolean
  },
  computed: {
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    optionButtonFlag() {
      return this.pageStatus == "Detail";
    },
    labelText() {
      return (label) => {
        let key = "action." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
  },
  inject: ["getModulesDataMethod"],
  data() {
    return {
      language: "EN",
      saveLoading: false,
      verifyLoading: false,
      addVerifyListLoading: false,
      previewPdfLoading: false,
      pageStatus: "Add",
    };
  },
  methods: {
    // 页面状态更新
    pageStatusChangeHandler(pageStatus) {
      this.pageStatus = pageStatus;
    },
    // 保存按钮点击
    saveHandle() {
      let type = this.$route.query.type;
      this.saveLoading = true;
      this.$emit("save", (res) => {
        let params = this.getSaveData(res);
        
        apiServer.getDataByType(type, "saveOrUpdate", params, () => {
          this.$message({
              message: this.$t("confirm.save_success"),
              type: "success",
            });
          this.saveLoading = false;
          if(this.$route.query.isCopy=='true'||this.$route.query.isCopy==true){
            setTimeout(() => {
              this.$router.go(-1);
            }, 200);
          }
        });
      });
    },
    getButtonTextByLanguage() {},
    addVerifyListHandle() {

      this.$confirm("Add To Verify List？", "Tips", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        let type = this.$route.query.type;
        this.addVerifyListLoading = true;
        this.$emit("save", (res) => {
          let params = this.getSaveData(res);
          
          apiServer.getDataByType(type, "addVerifyList", params, () => {
            this.$message({
                message: this.$t("confirm.save_success"),
                type: "success",
              });
            this.addVerifyListLoading = false;
          });
        });
        
      });
    },
    getSaveData(data) {
      let paramsId = "";
      const isCopy = this.$route.query.isCopy === 'false' ? false : !!this.$route.query.isCopy;
      if (this.$route.query.id && !isCopy) {
        paramsId = this.$route.query.id;
      }
      
      let saveData = {
        id: paramsId,
        itemCode: data.base_info.item_code,
        productType: data.base_info.product_type,
        productName: data.base_info.product_name,
        productDescription: data.base_info.product_description,
        physicalProperties: data.base_info.physical_properties,
        countryOfOrigin: data.general_specification.country_of_origin,
        botanicalName: data.general_specification.botanical_name.toString(),
        shelflifeNumber: data.other_notes.shelflife_number,
        verifiedListUser: "",
        verifiedList: 0,
        revNumber: 1,
        verified_time:"",
        rev_number:1,
        jsonValue: JSON.stringify(data),
      };

      // console.info("saveData:", saveData);
      return saveData;
    },
    verifyHandle() {
      let verifyTips = "Are You Sure About Verify?";
      let tipsText = "Tips";
      if (this.language == "CN") {
        verifyTips = "确定要审核吗？";
        tipsText = "温馨提示";
      }
      this.$confirm(verifyTips, tipsText, {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        // let data = this.getFormDataMethod();
        this.$emit("save", (data) => {
          
          // 检查 revisionData
          let revision = data.rev.revision[data.rev.revision.length - 1];
          console.info("revision:", revision);
          let msg = "";
          if (revision.requestedBy.length == 0) {
            msg = this.$t("confirm.requested_by_empty")
          } else if (revision.itemsRevised == 0) {
            msg = this.$t("confirm.items_revised_empty")
          } else if (revision.editedBy == 0) {
            msg = this.$t("confirm.edited_by_empty")
          } else if (revision.reason == 0) {
            msg = this.$t("confirm.reason_empty")
          }
          if (msg != "") {
            this.$message({
              type: "error",
              message: msg,
            });
            return;
          }
          console.info("verifyHandle");
          let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
          revision.isComplete = true;
          revision.approvedBy = userInfo.username;
          revision.verifyName = userInfo.username;
          revision.verifyTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

          data.rev.revision[data.rev.revision.length - 1] = revision;
          let saveData = this.getSaveData(data);

          console.info("data:", saveData);
          this.verifyLoading = true;
          this.verifyNewSpec(saveData);
      });

        
      });
    },
    verifyNewSpec(params) {
      console.info('verifyNewSpec params:',params)
      let api = "";
      if (this.language == "CN") {
        api = this.$api.handleSpec.verifyCnNewSpec(params);
      } else {
        api = this.$api.handleSpec.verifyNewSpec(params);
      }

      api
        .then(() => {
          this.verifyLoading = false;
          // this.loadDetailDataMethod();
          this.$message({
            type: "success",
            message: "Update Success",
          });
        })
        .catch((err) => {
          this.verifyLoading = false;
          console.log(err);
        });
    },
    // 预览打印
    previewHandle() {

      this.$emit("save", (res) => {
        let saveData = this.getSaveData(res);
        let versionNumber = "";
        let previewPdf = true; //编辑状态为 true
        let pageStatus = localStorage.getItem('pageStatus')
        if (pageStatus == "Detail") {
          previewPdf = false;
          let currentTabName = localStorage.getItem("currentTabName")
          versionNumber = currentTabName.replace("audited_tab","");
          versionNumber = parseInt(versionNumber) - 1;
          versionNumber = "V_" + versionNumber + ".0";
        }

        let defaultNotes = JSON.parse(localStorage.getItem("defaultNotes")) || [];
        console.info("defaultNotes:", defaultNotes);
        let notes = defaultNotes;
        if (defaultNotes.length > 0) {
          const jsonValue = JSON.parse(saveData.jsonValue);
          let check = localStorage.getItem('showNoteChecked')
          defaultNotes= defaultNotes.filter(note => !note.includes("（可选打印）"));
          notes = defaultNotes;
          if(check=='false'||check==false){
            // 删除带有 可选打印的那条记录
            jsonValue.other_notes.is_show_note = false
          }
          
          if(res.other_notes.notes.length>0){
            notes = res.other_notes.notes.toString().split(',').concat(defaultNotes.map(note => note.replace(/\*/g, '').replace(/（可选打印）/g, '')));
          }
          notes = notes.map(note => note.replace(/\*/g, ''));
          jsonValue.other_notes.notes = notes;
          saveData.jsonValue = JSON.stringify(jsonValue);
        }
        //预览后端需要的字段为 json_value
        // let printTitle = JSON.parse(saveData.jsonValue).base_info.print_title;

        saveData.json_value = saveData.jsonValue;
        saveData.verified_time = localStorage.getItem("verifiedTime");
        delete saveData.jsonValue;

        console.info("previewData:", saveData);
        let languageType = "US";
        let lang = localStorage.getItem("language").toLocaleLowerCase();
        if (lang=='cn'||lang=='zh') {
          languageType = "China";
        }
        let file_name = saveData.productName.replaceAll("%","") +"_" +saveData.itemCode +"_"+versionNumber;
        file_name = file_name.replace(/[%<>:"/\\|?*]/g, "");
        
        if(previewPdf){
          if(languageType=="China"){
            file_name = "(Preview 中文 Spec)"+file_name;
          }else{
            file_name = "(Preview Spec)"+file_name;
          }
        } else{
          if(languageType=="China"){
            file_name = "(中文 Spec)"+file_name;
          }else{
            file_name = "(Spec)"+file_name;
          }
          
        }
        let params = {
          language: languageType,
          data: saveData,
          cutomer_signature: this.isPrintSignature,
          preview_pdf: previewPdf,  
          file_name: file_name,
          response_type: "text",
        };

        this.previewServer(params)
        
      });

    },
    previewServer(params){
      this.previewPdfLoading = true;
      this.$axios
        .post("https://spec.dracoherbs.com/flask/generate_spec", params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.previewPdfLoading = false;
          let fileUrl =
            "https://spec.dracoherbs.com/download/" + response.data.file_name;
          setTimeout(function () {
            window.open(fileUrl);
          }, 200);
        })
        .catch((error) => {
          this.previewPdfLoading = false;
          console.error(error);
        });
    },
    backHomeClick() {
      window.history.go(-1);
    },
    recoverHandle() {
      let msg = "Are you sure to restore from this version?";
      let tipsText = "Tips";
      let okText = "OK";
      let cancelText = "Cancel";
      if (this.language == "CN") {
        msg = "您确定要从这个版本还原吗?";
        tipsText = "温馨提示";
        okText = "确定";
        cancelText = "取消";
      }
      this.$confirm(msg, tipsText, {
        confirmButtonText: okText,
        cancelButtonText: cancelText,
        type: "warning",
      }).then(() => {
        this.loadRecoverDataMethod();
      });
    },
  },
  created() {
    this.language = localStorage.getItem('language')
    this.$EventBus.$on("pageStatusChange", this.pageStatusChangeHandler);
  },
};
</script>